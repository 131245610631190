@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.headline {
  p {
    margin-bottom: 0px;
  }
  text-align: center;
  padding: 0px 40px 0px 40px;

  h1 {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(lg) {
    h1 {
      margin-bottom: 60px;
    }
  }
}
