@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.slick-dots li {
  height: 7px;
  width: 7px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
}

@include media-breakpoint-up(md) {
  .slick-dots li {
    height: 12px;
    width: 12px;
    margin-right: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .slick-dots li {
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }
}

.slick-custom {
  position: relative;
  padding-top: 20px;
  bottom: 0;
  div {
    height: 6px;
    width: 6px;
    border-radius: 50px;
    border: 1px solid #0cb7b7;
  }
  .slick-active div {
    background-color: #0cb7b7;
  }
}

@include media-breakpoint-up(md) {
  .slick-custom {
    div {
      height: 15px;
      width: 15px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .slick-custom {
    div {
      height: 20px;
      width: 20px;
    }
  }
}
