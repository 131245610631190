@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.download-button {
  width: 150px;
}

@include media-breakpoint-up(lg) {
  .download-button {
    margin-top: 30px;
    width: 200px;
  }
}
